import React, { useState } from 'react'; 
import Select , { components } from 'react-select';

const CustomOption = (props) => {
  const { data, isSelected, innerRef, innerProps } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        backgroundColor: isHovered
          ? '#cedae6' 
          : isSelected
          ? '#cedae6'
          : 'white',
        color: isSelected ? '#004080' : 'black',
        padding: '10px',
        fontWeight: isSelected ? 'bold' : 'normal',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)} 
    >
      <input
        type="checkbox"
        checked={isSelected}
        readOnly
        style={{ marginRight: '0.3rem' }}
      />
      {data.label}
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? null : null
      )}
    </components.ValueContainer>
  );
};

export const CustomFilter = ({ onFilter, column, dataType, data }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  let options = Object.keys(data).map(key => {
    return { label: data[key], value: key };
  });

  const handleChange = (selected) => {
    const values = selected ? selected.map(entry => entry.value) : []; 
    setSelectedValues(values); 
    onFilter(values); 
  };

  return (
    <div style={{ textTransform: 'lowercase', fontSize: '13px' }}>
      <Select
        placeholder={`Select ${dataType}...`}
        isClearable
        isMulti
        options={options}
        value={options.filter((option) => selectedValues.includes(option.value))}
        className="providerFilter"
        onChange={handleChange}
        components={{
          Option: CustomOption,
          ValueContainer: CustomValueContainer
        }}        
        filterOption={() => true} 
        closeMenuOnSelect={false} 
        hideSelectedOptions={false} 
      />
    </div>
  );
};