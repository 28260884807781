const _ = require('lodash')
const moment = require('moment')

import React, { useEffect, useState, useRef } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import { Card, Row, Col, Button } from 'react-bootstrap'
import SportSelect from 'components/Inputs/SportSelect'
import DateSelect from 'components/Inputs/DateSelect'
import Swal from 'sweetalert2'

import { postData, getData } from 'utils/server'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import SelectedCounter from 'components/UniObject/SelectedCounter'

import { highlightedFixturesValidation } from 'utils/validations'
import ParticipantFixtures from 'components/Modals/ParticipantFixtures'

import { CustomFilter } from 'components/Filters/customFilter'

export default function HLFixtures(providers) {
  const controller = new AbortController()
  const tableRef = useRef()
  const notificationAlertRef = React.useRef(null)
  const [disableSearch, setDisableSearch] = useState(true)
  const [disableMatch, setDisableMatch] = useState(true)
  const [icon, setIcon] = useState(<i className="fas fa-search text-primary"></i>)
  const [text, setText] = useState(<span className="text-primary">Search fixtures</span>)
  const [matchIcon, setMatchIcon] = useState(<i className="fas fa-trash text-danger"></i>)
  const [matchText, setMatchText] = useState(<span className="text-danger">Delete selected</span>)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [selectedcounter, setSelectedCounter] = useState(0)
  const [allTableData, setAllData] = useState([])
  const [sports, setSports] = useState([])
  const [selectedSport, setSelectedSport] = useState(null)
  const [sorted, setSorted] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [fixture, setFixture] = useState(null)
  const [checked, setChecked] = useState([])
  const [refreshTable, setRefreshTable] = useState(false)
  const [providerFilters, setProviderFilters] = useState({})
  const [tableKey, setTableKey] = useState(Math.random())
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedProvider, setSelectedProvider] = useState([])
  const [selectedId, setSelectedId] = useState([])
  const [providerId, setSelectedProviderId] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedSportId, setSelectedSportId] = useState(0)
  const [sortFieldTo, setSortFieldTo] = useState('')
  const [sortFieldFrom, setSortFieldFrom] = useState('')

  const handleMultiSelectedEvents = (events, isSelected) => {
    for (let index = 0; index < events.length; index++) {
      const event = events[index]
      fetchSelected(event, isSelected)
    }
  }

  const handlePageChange = (page, sizePerPage) => {
    setCurrentPage(page)
  }
  const updateSortField = event => {
    //console.log('sortFieldTo', event.target.name)
    if (event.target.id == 'sortFieldFrom') setSortFieldFrom(event.target.value)
    if (event.target.id == 'sortFieldTo') setSortFieldTo(event.target.value)

    //console.log('sortFieldTo', event.target.value)
  }
  const fetchSelected = (row, isSelect) => {
    if (isSelect == true) {
      checked.push(allTableData.find(o => o.pid == row.pid))
      setSelectedCounter(checked.length)
    } else if (isSelect == false) {
      let idToRemove = row.pid
      let index = checked
        .map(o => {
          return o.pid
        })
        .indexOf(idToRemove)
      checked.splice(index, 1)
      setSelectedCounter(checked.length)
    }
  }

  const selectRow = {
    clickToSelect: false,
    selectColumnPosition: 'right',
    style: { backgroundColor: '#24e1cf1a' },
    mode: 'checkbox',
    onSelect: (ev, selected) => {
      if (ev) fetchSelected(ev, selected)
    },
    hideSelectAll: false,
    onSelectAll: (selected, events) => {
      handleMultiSelectedEvents(events, selected)
    }
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,

    sizePerPage: 10,
    page: currentPage,
    onPageChange: handlePageChange
  }

  const sport = async () => {
    return selectedSport
  }

  const date1 = async () => {
    return startDate
  }

  const date2 = async () => {
    return endDate
  }

  const checkedList = async () => {
    return checked
  }

  const fetchFilters = async controller => {
    let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (sports_response != null) {
      setSports(sports_response.data)
    }
  }

  const handleInfoClick = (cellContent, row) => {
    setSelectedProvider(row.provider)
    setSelectedId(cellContent)

    const providerObject = providers.providers.find(provider => provider.name === row.provider)

    // If the provider object is found, get the provider_id
    const provider_id = providerObject ? providerObject.id : null
    setSelectedProviderId(provider_id)
    setSelectedSportId(selectedSport.id)

    setShowModal(true)
  }

  const handleCloseFixtureModal = () => {
    setShowModal(false)
  }

  const columns = [
    {
      dataField: 'pid',
      text: 'Event ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'provider',
      text: 'Provider',
      align: 'center',
      editable: false,
      headerClasses: 'top-align',
      filterRenderer: (onFilter, column) =>   
        <CustomFilter
          onFilter={values => {
            onFilter(values);
          }}
          dataType="Providers"
          data={providerFilters} 
        />, 
      filter: customFilter({
        type: FILTER_TYPES.MULTISELECT
      }),
      classes: (cellContent, row) => {
        return `${cellContent}Cell`
      },
      formatter: cell => providerFilters[cell]
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter(),
      sort: true,
      classes: 'custom-cell-name',
      formatter: (cellContent, row) => <div className="text-primary">{cellContent}</div>
    },
    {
      dataField: 'home_id',
      text: 'Home ID',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <Row>
          {row.home_uni_id ? (
            <Col md="6" className="text-success" title={`Uni ID: ${row.home_uni_id}`}>
              {cellContent}
            </Col>
          ) : (
            <Col md="6">{cellContent}</Col>
          )}
          <Col md="6 text-right">
            <a href="#" onClick={() => handleInfoClick(cellContent, row)} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
              <i className="fas fa-info"></i>
            </a>
          </Col>
        </Row>
      )
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.home_uni_id ? (
            <div className="text-success" title={`Uni ID: ${row.home_uni_id}`}>
              {cellContent}
            </div>
          ) : (
            cellContent
          )}
        </div>
      )
    },
    {
      dataField: 'away_id',
      text: 'Away ID',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <Row>
          {row.away_uni_id ? (
            <Col md="6" className="text-success" title={`Uni ID: ${row.away_uni_id}`}>
              {cellContent}
            </Col>
          ) : (
            <Col md="6">{cellContent}</Col>
          )}
          <Col md="6 text-right">
            <a href="#" onClick={() => handleInfoClick(cellContent, row)} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '2px 5px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '12px', margin: '4px 2px', cursor: 'pointer' }}>
              <i className="fas fa-info"></i>
            </a>
          </Col>
        </Row>
      )
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter(),
      sort: true,
      formatter: (cellContent, row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.away_uni_id ? (
            <div className="text-success" title={`Uni ID: ${row.away_uni_id}`}>
              {cellContent}
            </div>
          ) : (
            cellContent
          )}
        </div>
      )
    },
    {
      dataField: 'created',
      text: 'Created',
      sort: true
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      sort: true
    }
  ]

  const fetchData = async (event, controller) => {
    setChecked([])
    setDisableSearch(true)
    setIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setText(<span className="text-primary">Please wait...</span>)
    let currentSport = await sport()
    let startDate = await date1()
    let endDate = await date2()

    let filters = {
      sport_id: currentSport.value,
      from_date: new Date(startDate).getTime(),
      to_date: new Date(endDate).getTime(),
      sorted: sorted,
      uc_sort_to: sortFieldTo,
      uc_sort_from: sortFieldFrom
    }

    // console.log('filters', filters)

    let validation = highlightedFixturesValidation(filters)

    if (validation != null) {
      notificationAlertRef.current.notificationAlert(notificationOptions(validation.message, 'danger'))
      setDisableSearch(true)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }

    let uri = '/api/fixture/highlightedFixtures'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
      return
    }
    if (_.isEmpty(response.data)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
      setDisableSearch(false)
      setIcon(<i className="fas fa-search text-primary"></i>)
      setText(<span className="text-primary">Search fixtures</span>)
    }

    let tableData = []
    for (let fixture in response.data) {
      let jsDate1 = new Date(parseInt(response.data[fixture].start_date))
      let jsDate2 = new Date(parseInt(response.data[fixture].created) * 1000)
      let f_key = new Date() + '-' + currentSport.label + '-' + fixture
      response.data[fixture].f_key = f_key
      response.data[fixture].start_date = moment(jsDate1).format('MM/DD/YYYY, HH:mm')
      response.data[fixture].created = moment.utc(jsDate2).format('MM/DD/YYYY, HH:mm')
      tableData.push(response.data[fixture])
    }

    tableData.sort((a, b) => (a.start_date > b.start_date ? 1 : -1))

    setAllCounter(tableData.length)
    setAllData(tableData)
    setData(tableData)
    // console.log('tableData', tableData)
    setDisableSearch(false)
    setIcon(<i className="fas fa-search text-primary"></i>)
    setText(<span className="text-primary">Search fixtures</span>)
  }

  const createFilters = () => {
    let providersArray = providers.providers
    if (providersArray && providersArray.length > 0) {
      let myProviderFilters = {}
      for (let provider of providersArray) myProviderFilters[provider.name] = provider.name
      setProviderFilters(myProviderFilters)
      // console.log(providerFilters)
    }
  }
  const deleteRows = async () => {
    let currentSport = await sport()
    let currentChecked = await checkedList()
    let htmlData = Object.keys(currentChecked).length > 1 ? `${Object.keys(currentChecked).length} Highlights ready to delete!` : `${Object.keys(currentChecked).length} Highlight ready to delete!`

    if (currentChecked.length == 0) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error. Select some fixtures first', 'danger'))
      return
    }

    setMatchIcon(<i className="fas fa-spinner fa-spin text-danger"></i>)
    setMatchText(<span className="text-danger">Please wait...</span>)

    Swal.fire({ title: checked.length > 1 ? `Delete fixtures?` : `Delete fixture?`, html: htmlData, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Delete', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let request = []
        for (let i = 0; i < checked.length; i++) {
          request.push({
            id: parseInt(currentChecked[i].pid),
            sport_id: currentSport.value
          })
        }
        let result = await postData('/api/fixture/deleteHighlight', request, { Authorization: getAuthorizationHeader() })
        if (result == null) {
          setMatchIcon(<i className="fas fa-trash text-danger"></i>)
          setMatchText(<span className="text-danger">Delete selected</span>)
          return
        }
        setChecked([])
        await fetchData(new Event('FaceEvent'), controller)
        setMatchIcon(<i className="fas fa-trash text-danger"></i>)
        setMatchText(<span className="text-danger">Delete selected</span>)
        if (result != null) {
          Swal.fire({ title: `Highlights deleted successfully!`, html: '', showDenyButton: false, showConfirmButton: true, confirmButtonText: 'OK', denyButtonText: 'Cancel', icon: 'success', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
            refreshTable == true ? setRefreshTable(false) : setRefreshTable(true)
          })
        } else {
          Swal.fire('Error!', `- Highlights ${result ? 'deleted' : 'cannot be deleted!'} <br> -Message for Delete didnt send!`, 'error')
        }
      }
      setMatchIcon(<i className="fas fa-trash text-danger"></i>)
      setMatchText(<span className="text-danger">Delete selected</span>)
    })
  }

  const deselectAllRows = () => {
    setChecked([])
    setSelectedCounter(0)
    setTableKey(Math.random())
  }

  const updateStartDate = startDate => {
    setStartDate(startDate)
  }

  const updateEndDate = endDate => {
    setEndDate(endDate)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
  }

  const updateSorted = e => {
    setSorted(e.target.checked)
    if (!e.target.checked) {
      setSortFieldTo('')
      setSortFieldFrom('')
    }
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    createFilters()
  }, [providers])

  useEffect(() => {
    if (fixture == null) return
    setDisableMatchFixtures(false)
  }, [fixture])

  useEffect(() => {
    if (selectedSport == null || startDate == null || endDate == null) return
    if (startDate == 'Invalid date' || endDate == 'Invalid date') {
      setDisableSearch(true)
      return
    }
    setDisableSearch(false)
  }, [selectedSport, startDate, endDate])

  useEffect(() => {
    fetchFilters(controller)
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    setDisableMatch(true)
    if (checked.length > 0) setDisableMatch(false)
    setSelectedCounter(checked.length)
  }, [checked.length])

  return (
    <>
      <ParticipantFixtures showModal={showModal} sportId={selectedSportId} pid={selectedId} providerId={providerId} providerName={selectedProvider} signalCloseModal={handleCloseFixtureModal}></ParticipantFixtures>
      <Card className="strpied-tabled-with-hover">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Card.Body>
          <Row>
            <Col md="2">
              <label>From:</label>
              <DateSelect setParentData={updateStartDate} showTime={true} />
            </Col>
            <Col md="2">
              <label>To:</label>
              <DateSelect setParentData={updateEndDate} showTime={true} minDate={startDate ? startDate : null} />
            </Col>
            <Col md="2">
              <label>Sport:</label>
              <SportSelect sports={sports} updateSport={updateSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
            </Col>
            <Col md="2" lg="2" xl="2" style={{ marginBottom: 'auto', marginTop: 'auto', width: '200px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input type="checkbox" id="sorted" checked={sorted} onChange={updateSorted} />
                <label style={{ paddingLeft: '3px' }} htmlFor="region">
                  Sorted Competitions
                </label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <input type="text" id="sortFieldFrom" onChange={updateSortField} disabled={!sorted} style={{ marginTop: '5px' }} />{' '}
                <label style={{ paddingLeft: '3px', marginTop: '5px' }} htmlFor="sortFieldFrom">
                  Sort (from)
                </label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <input type="text" id="sortFieldTo" onChange={updateSortField} disabled={!sorted} style={{ marginTop: '5px' }} />{' '}
                <label style={{ paddingLeft: '3px', marginTop: '5px' }} htmlFor="sortFieldTo">
                  Sort (to)
                </label>
              </div>
            </Col>
            <Col md="2 mt-4 text-right">
              <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disableSearch}>
                {icon} {text}
              </Button>
            </Col>
            <Col md="2 mt-4 text-center">
              <Button variant="danger" onClick={deleteRows} disabled={disableMatch}>
                {matchIcon} {matchText}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="strpied-tabled-with-hover">
        <Card.Body>
          <Row>
            <Col md="12">
              <h4>Provider Fixtures:</h4>
            </Col>
          </Row>
          <Row className="justify-content-md-end">
            <Col md="auto">
              <SelectedCounter all={allcounter} selected={selectedcounter}></SelectedCounter>
            </Col>
            <Col md="auto">
              <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            </Col>

            <Col md="auto mb-2">
              <Button variant="secondary" onClick={deselectAllRows}>
                Deselect All
              </Button>
            </Col>
          </Row>
          <BootstrapTable key={tableKey} bootstrap4 keyField="f_key" data={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} filter={filterFactory({ afterFilter })} selectRow={selectRow} hover />
        </Card.Body>
      </Card>
    </>
  )
}
