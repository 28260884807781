import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import UniParticipants from 'components/Tables/UniParticipants'
import AllProvidersParticipants from 'components/Tables/AllProvidersParticipants'
import ProviderSelect from 'components/Inputs/ProviderSelect'
import CreateUniParticipants from 'components/Modals/CreateUniParticipants'

import { getData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import { set } from 'lodash'

function AllParticipants() {
  const [providerId, setProviderId] = useState(0)
  const [updateUniParticipants, setUpdateUniParticipants] = useState(false)
  const [showCreateParticipantModal, setCreateParticipantModal] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [providers, setProviders] = useState([])
  const [downloadcsv, setDownloadcsv] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [updateProviderParticipants, setUpdateProviderParticipants] = useState(false)

  const updateSelectedProvider = (pIdOrProviders) => {
    if (Array.isArray(pIdOrProviders)) {
      setProviders(pIdOrProviders);
    } else {
      setProviderId(pIdOrProviders);
    }
  };

  const clickDownloadUniParticipants = () => {
    setDownloadcsv(true)
    setDisabled(true)
  }

  const handleUniParticipants = data => {
    if (data.downloadcsv && data.downloadcsv == true) {
      setDisabled(false)
      setDownloadcsv(false)
    }
  }

  const handleCreteParticipantClick = () => {
    setCreateParticipantModal(true)
  }

  const handleProvidersParticipantCommunication = data => {
    if (data.refresh && data.refresh == true) {
      setUpdateUniParticipants(false)
      setUpdateUniParticipants(true)
    }
  }

  const handleUniParticipantsCommunication = data => {
    if (data.refresh && data.refresh == true) {
      setUpdateProviderParticipants(false)
      setUpdateProviderParticipants(true)
    }
  }

  const handleCloseModal = updateUParticipants => {
    setCreateParticipantModal(false)
    if (updateUParticipants) {
      updateUniParticipants == true ? setUpdateUniParticipants(false) : setUpdateUniParticipants(true)
    }
  }

  const fetchSports = async () => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() })
    if (response != null) {
      setUniSports(response.data)
    }
  }

  useEffect(() => {
    fetchSports()
  }, [])
  return (
    <>
      <Container fluid>
        <CreateUniParticipants showModal={showCreateParticipantModal} signalCloseModal={handleCloseModal} sports={uniSports} />
        <Row>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="8">
                    <Card.Title as="h4">Uni Participants</Card.Title>
                  </Col>
                  <Col md="4">
                    <Row>
                      <Col md="8" sm="12">
                        <Button style={{ backgroundColor: 'rgb(44, 47, 45)' }} className="create-region-btn" variant="success" onClick={clickDownloadUniParticipants}>
                          Download
                        </Button>
                      </Col>
                      <Col md="4" sm="12">
                        <Button className="create-region-btn" variant="success" onClick={handleCreteParticipantClick}>
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <UniParticipants forceUpdate={updateUniParticipants} sports={uniSports} informParent={handleUniParticipants} informer={handleUniParticipantsCommunication} downloadcsv={downloadcsv} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Card.Title as="h4">Provider Participants</Card.Title>
                  </Col>
                  <Col md="6">
                    <ProviderSelect setParentProviderId={updateSelectedProvider} defaultValue={0} />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Col md="12">
                  <AllProvidersParticipants informParent={handleProvidersParticipantCommunication} forceUpdate={updateProviderParticipants} providers={providers} sports={uniSports} />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AllParticipants
